import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Typography } from '@material-ui/core'
import LinkSharpIcon from '@material-ui/icons/LinkSharp'
import BookmarksSharpIcon from '@material-ui/icons/BookmarksSharp'
import EmailIcon from '@material-ui/icons/Email'
import { makeStyles } from '@material-ui/styles'
import TwitterIcon from '@material-ui/icons/Twitter'
import copy from 'clipboard-copy'
import FacebookIcon from '@material-ui/icons/Facebook'
import {
  EmailShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share'
const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 10,
  },
  copied: {
    backgroundColor: '#3091E7',
  },
  disabled: {
    '&$disabled': {
      opacity: 1,
    },
  },
  menu: {
    display: 'flex',
    flexDirection: 'column',
  },
}))
const CardMenu = ({ onClose, anchorEl, url }) => {
  const [state, setState] = useState({
    copied: false,
    saved: false,
  })
  const onCopy = e => {
    e.stopPropagation()
    copy(url)
    setState({
      ...state,
      copied: true,
    })
    setTimeout(
      () =>
        setState({
          ...state,
          copied: false,
        }),
      5000
    )
  }
  const onSave = e => {
    e.stopPropagation()
    setState({
      ...state,
      saved: true,
    })
  }
  const classes = useStyles()
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      // keepMounted

      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      onClick={e => e.stopPropagation()}
      //   className={classes.menu}
    >
      <MenuItem>
        <FacebookShareButton
          url={url}
          style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          quote="Checkout this recipe"
        >
          <FacebookIcon className={classes.icon} />
          <Typography>Facebook</Typography>
        </FacebookShareButton>
      </MenuItem>
      <MenuItem>
        <TwitterShareButton
          url={url}
          style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          title="Checkout this recipe"
          via="mybee-app"
        >
          <TwitterIcon className={classes.icon} />
          <Typography>Twitter</Typography>
        </TwitterShareButton>
      </MenuItem>
      <MenuItem>
        <EmailShareButton
          url={url}
          style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
        >
          <EmailIcon className={classes.icon} />
          <Typography>Email</Typography>
        </EmailShareButton>
      </MenuItem>
      <MenuItem
        onClick={onClose}
        onClick={onCopy}
        className={state.copied && classes.copied}
        disabled={state.copied}
        classes={{
          root: classes.disabled,
        }}
      >
        <LinkSharpIcon className={classes.icon} />
        <Typography>{!state.copied ? 'Copy URL' : 'Copied'}</Typography>
      </MenuItem>
    </Menu>
  )
}

export default CardMenu
