import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  IconButton,
  CircularProgress,
  Grow,
  Fade,
} from '@material-ui/core'
import ShareSharpIcon from '@material-ui/icons/ShareSharp';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { navigate } from 'gatsby'
import Img from 'react-image'
import { Skeleton } from '@material-ui/lab'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CardMenu from './CardMenu'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
const regex = /(\/upload\/)/
const optimizedImageUrl = (url, width) => {
  return (
    typeof window !== 'undefined' &&
    url.replace(
      regex,
      `/upload/w_${width},h_${Math.floor(
        (width * 5) / 8
      )},c_scale,q_auto:eco,f_auto/`
    )
  )
}
const fadeOut = 400,
  fadeIn = 600
const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    '&$focused': {
      color: null,
      backgroundColor: null,
    },
  },
  media: {
    height: 250,
    // width: '100%',
    // margin: '0 auto',
  },
  loader: {
    padding: '70px 0',
    textAlign: 'center',
  },
  content: {
    maxHeight: '10em',
    padding: 0,
    // paddingTop: '6px',
  },
  title: {
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    maxHeight: '1.3em',
    lineHeight: '1.3em',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontWeight: 800,
  },
  intro: {
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    maxHeight: '5.8em',
    lineHeight: '1.3em',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  paperElevation1: {
    backgroundColor: 'transparent',
  },
  focusHighlight: {},
  actionArea: {
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
}))
const ItemCard = ({ docId, name, cover, intro, loading, fetching }) => {
  const [width, setWidth] = useState(400)
  const [anchorEl, setAnchorEl] = useState(null)
  const imgRef = useRef(null)
  useEffect(() => {
    setWidth(imgRef.current.offsetWidth)
  }, [])
  const classes = useStyles()
  const onClick = () => navigate(`/view?id=${docId}`)
  const onMenuClick = e => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }
  const onMenuClose = e => {
    e.stopPropagation()
    setAnchorEl(null)
  }
  return (
    <Card
      className={classes.card}
      classes={{
        root: classes.paperElevation1,
      }}
      elevation={0}
    >
      <CardActionArea
        ref={imgRef}
        // disable={classes.card}
        onClick={onClick}
        disableRipple
        disableTouchRipple
        focusRipple={false}
        classes={{
          root: classes.actionArea,
          focusHighlight: classes.focusHighlight,
        }}
        // focusVisibleClassName={classes.focusHighLight}
      >
        {loading ? (
          <Skeleton
            className={classes.media}
            width="100%"
            style={{ marginTop: 0 }}
          />
        ) : (
          <Img
            src={[optimizedImageUrl(cover, width), cover]}
            // className={classes.media}
            // height="250"
            style={{margin: '0 0'}}
            loader={
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            }
            decode={false}
            // container={children => (
            //   <ReactCSSTransitionReplace
            //     transitionEnterTimeout={fadeIn * 10}
            //     transitionLeaveTimeout={fadeOut}
            //     transitionName="fade-wait"
            //     changeWidth={true}
            //   >
            //     {children}
            //   </ReactCSSTransitionReplace>
            // )}
          />
        )}
        <CardContent className={classes.content}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            }}
          >
            {loading ? (
              <Skeleton width="70%" height="1.3em" style={{ marginTop: 0 }} />
            ) : (
              <Typography
                className={classes.title}
                // gutterBottom
                variant="h5"
                component="p"
              >
                {name}
              </Typography>
            )}
            <div style={{ flexGrow: 1 }} />
            <IconButton
              size="small"
              onClick={onMenuClick}
              disableRipple
              disableFocusRipple
            >
              <ShareSharpIcon />
            </IconButton>
            <CardMenu
              anchorEl={anchorEl}
              onClose={onMenuClose}
              url={`https://mybee.cf/view?id=${docId}`}
            />
          </div>
          {loading ? (
            <>
              <Skeleton width="100%" height="1em" style={{ marginTop: 0 }} />
              <Skeleton width="100%" height="1em" style={{ marginTop: 0 }} />
              <Skeleton width="100%" height="1em" style={{ marginTop: 0 }} />
              <Skeleton width="30%" height="1em" style={{ marginTop: 0 }} />
            </>
          ) : (
            <Typography className={classes.intro}>{intro}</Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ItemCard
